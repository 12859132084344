<template>
    <ValidationProvider
        ref="validator"
        v-slot="{ errors }"
        class="checkbox-wrapper"
        disabled
        tag="div"
        :rules="setRules()"
    >
        <div class="field__inner">
            <div
                v-for="(item, index) in field.items"
                :key="index"
                class="field field--checkbox"
            >
                <input
                    :id="`field-${_uid}-checkbox-${index}`"
                    v-model="inputVal"
                    type="checkbox"
                    :disabled="field.disabled || disabled"
                    :value="item.value"
                    :required="
                        field.options && field.options.optional ? false : true
                    "
                />
                <label :for="`field-${_uid}-checkbox-${index}`">{{
                    item.label
                }}</label>
            </div>
        </div>
        <span>{{ errors[0] }} </span>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
    name: "Checkbox",
    components: {
        ValidationProvider
    },
    props: {
        field: {
            type: Object,
            default: () => {}
        },
        value: {
            type: Boolean,
            default: false
        },
        validation: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            settings: {
                checked: []
            }
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    created() {
        // this.field.items.filter(x => {
        //     console.log(x);
        //     if (x.checked && this.field.items.length > 1) {
        //         this.field.value.push(x.value);
        //     } else if (x.checked) {
        //         this.field = x.value;
        //     }
        // });
    },
    methods: {
        setRules() {
            return {
                required: this.rule("required")
            };
        },
        rule(name) {
            if (name === "required") {
                return (
                    this.field &&
                    !(
                        (this.field.options && this.field.options.optional) ||
                        !this.required
                    )
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
    align-items: center;

    height: 30px;

    .field__inner {
        display: flex;

        margin: -0.5rem;
    }

    .field--checkbox {
        margin: 0.5rem;

        input {
            position: absolute;

            display: inherit;

            margin: 0;
            padding: 0;

            text-indent: -999999%;

            opacity: 0;

            & + label {
                position: relative;

                display: inline-flex;
                align-items: center;

                margin-bottom: 0;

                cursor: pointer;

                color: $body-color;

                font-size: 0.9375rem;
                line-height: 1rem;

                &::before {
                    width: 30px;
                    height: 30px;
                    margin-right: 1rem;

                    content: "";

                    border: 1px solid #ebedef;
                    border-radius: 4px;
                }

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;

                    visibility: hidden;

                    width: 30px;
                    height: 30px;
                    margin-right: 0.5rem;

                    content: "";

                    opacity: 0;
                    border-radius: 4px;
                    background-image: url("./checked.svg");
                    background-size: 100%;
                }
            }

            &:disabled + label {
                pointer-events: none;

                opacity: 0.5;
            }
        }

        input:checked + label {
            &::after {
                visibility: visible;

                opacity: 1;
            }
        }
    }
}
</style>
