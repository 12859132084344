import Vue from "vue";

// Time ago
import TimeAgo from "javascript-time-ago";
import nl from "javascript-time-ago/locale/nl";

TimeAgo.addLocale(nl);
const timeAgo = new TimeAgo("nl-NL");

Vue.filter("timeAgo", function(value) {
    return timeAgo.format(new Date(value));
});

// Date
var moment = require("moment");

Vue.filter("date", function(value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
});

Vue.filter("dateTime", function(value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY - HH:mm");
});

Vue.filter("time", function(value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
});

Vue.filter("array", function(value) {
    return value.join(", ");
});

// Money
var currencyFormatter = require("currency-formatter");

Vue.filter("money", function(value) {
    return currencyFormatter.format(value, {
        locale: "nl-NL",
        symbol: "€"
    });
});

// Number
Vue.filter("number", function(value) {
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
});
