<template>
    <ValidationProvider
        ref="validator"
        v-slot="{ errors }"
        class="field field--input"
        tag="div"
        :disabled="!validation"
        :rules="setRules()"
    >
        <div class="field__inner">
            <input
                :id="`field-${_uid}-input`"
                v-model="inputVal"
                :type="field ? field.type : type"
                :disabled="field ? field.disabled : disabled"
                :required="rule('required')"
                :autocomplete="autocomplete"
                :class="[
                    { 'is-focused': value },
                    { 'has-error': errors.length }
                ]"
                @focus="$emit('focus')"
            />
            <label :for="`field-${_uid}-input`">
                <span>{{ field ? field.label : label }}</span>
                <span v-if="rule('required')">*</span>
            </label>
            <div v-if="isLoading" class="spinner"></div>
        </div>
        <!-- <span v-if="errors.length && validation" class="msg msg--error">{{
            errors[0]
        }}</span> -->
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
    name: "Input",
    components: {
        ValidationProvider
    },
    props: {
        field: {
            type: Object,
            default: () => {}
        },
        value: {
            type: [String, Number],
            default: ""
        },
        validation: {
            type: Boolean,
            default: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: ""
        },

        /** IF there is no pre-defined JSON Field we're forced to use props. */

        label: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        rules: {
            type: Array,
            default: () => []
        },
        regex: {
            type: RegExp,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    methods: {
        setRules() {
            return {
                required: this.rule("required"),
                numeric: this.rule("numeric"),
                email: this.rule("email"),
                regex: this.rule("regex")
            };
        },
        rule(name) {
            if (name === "required") {
                return !(
                    (this.field &&
                        this.field.options &&
                        this.field.options.optional) ||
                    !this.required
                );
            } else if (name === "numeric") {
                return (
                    (this.field && this.field.type === "number") ||
                    this.type === "number"
                );
            } else if (name === "email") {
                return (
                    (this.field && this.field.type === "email") ||
                    this.type === "email"
                );
            } else if (name === "regex") {
                return this.field &&
                    this.field.options &&
                    this.field.options.regex
                    ? this.field.options.regex
                    : false;
            }
        }
    }
};
</script>

<style lang="scss">
.field--input {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .field__inner {
        position: relative;

        display: flex;
    }

    input {
        flex-grow: 1;
        flex-shrink: 0;

        width: 100%;
        padding: 0.75rem 1rem;

        color: var(--secondary-dark);
        border: 1px solid var(--grey-25);
        border-radius: 4px;

        &::placeholder {
            opacity: 0.5;
        }

        &:focus,
        &.is-focused {
            // border-color: var(--border-grey-dark);
        }

        &:disabled {
            @extend %input-disabled;
        }

        &.is-complete {
            @extend %input-complete;
        }

        &.has-error {
            @extend %input-error;
        }
    }

    input + label {
        position: absolute;
        top: 50%;
        left: 1.25rem;

        margin-left: -2px;

        transform: translateY(-50%);

        color: var(--dark-50);
        background-color: #fff;

        font-size: 0.95rem;

        @include transition(0.3s all);
    }

    input:focus + label,
    input.is-focused + label {
        top: 0;

        color: var(--dark-50);

        font-size: 0.75rem;

        &::placeholder {
            opacity: 0;
        }
    }

    .spinner {
        position: absolute;
        top: calc(50% - 7.5px);
        right: 1rem;
    }
}

%input-disabled {
    pointer-events: none;

    background-color: #ebedef;

    & + label {
        background-color: transparent;
    }
}

%input-complete {
    border-color: $success;
}

%input-warning {
    border-color: $warning;
}

%input-error {
    border-color: $danger;
}
</style>
