<template>
    <ValidationProvider
        ref="validator"
        v-slot="{ errors }"
        class="field field--select"
        tag="div"
    >
        <input-tag
            placeholder="Gebieden (postcodes)"
            v-model="inputVal"
            :validate="checkTag"
        ></input-tag>

        <span v-if="errors.length && validation" class="msg msg--error">{{
            errors[0]
        }}</span>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import InputTag from "vue-input-tag";

export default {
    name: "Tags",
    components: {
        ValidationProvider,
        InputTag
    },
    props: {
        value: {
            type: Array,
            default: () => {}
        },
        field: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ""
        },
        selectLabel: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            focus: false
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("change", val);
                this.$emit("input", val);
            }
        }
    },
    methods: {
        checkTag(tag) {
            const regex = RegExp(/^[1-9][0-9]{3}/i);

            if (regex.test(tag) && tag.length == 4) {
                return true;
            } else {
                return false;
            }
        },
        isFocused() {
            this.focus = true;
            this.$emit("focus");
        }
    }
};
</script>

<style lang="scss">
.vue-input-tag-wrapper {
    flex-grow: 1;
    flex-shrink: 0;

    width: 100%;
    padding: 0.5rem 1rem 0 1rem;

    border: 1px solid var(--grey-25);
    border-radius: 4px;
    input {
        padding: 0 !important;
        margin: 0.25rem 0 0.75rem 0 !important;
        color: var(--secondary-dark) !important;

        &::placeholder {
            color: var(--dark-50);
        }
    }
    .input-tag {
        margin: 0 0.5rem 0.5rem 0rem !important;

        padding-left: 0.5rem;

        background-color: var(--secondary-50);
        border: none;
        color: var(--secondary-dark);
        font-size: 1rem;
        display: flex;

        .remove {
            padding: 0 0.25rem 0 0.5rem;
            color: var(--secondary-dark);
            line-height: 20px;
        }
    }
}
</style>
