<template>
    <div>
        <div class="upload-container" :class="`${inline && 'inline'}`">
            <div
                class="upload-container__image"
                :class="`${loading && 'disabled'} size-${size}`"
            >
                <div
                    :class="
                        `upload-container__image__ratio ratio-${ratio} ${round &&
                            'round'}`
                    "
                >
                    <div v-if="hasImage">
                        <img
                            :src="hasImage"
                            ref="preview"
                            :class="[objectFit, { 'is-filled': hasImage }]"
                        />
                        <div class="delete" @click="deleteImage()">
                            <i class="i-close text-white" />
                        </div>
                    </div>
                    <label
                        v-else
                        :for="`file-${id}`"
                        class="upload-container__image__ratio__content"
                        :class="{ 'has-label': label }"
                    >
                        <i v-if="loading" class="i-load spinning"></i>
                        <i v-else-if="mode == 'image'" class="i-image"></i>
                        <i v-else-if="mode == 'video'" class="i-video"></i>
                        <label class="title bold" v-if="label">{{
                            label
                        }}</label>
                    </label>
                </div>
            </div>
            <!-- <span
                v-if="value"
                class="subtitle clickable"
                @click="$emit('input', null)"
                >Verwijder {{ mode == "image" ? "afbeelding" : "video" }}</span
            > -->
        </div>
        <form ref="form">
            <input
                tabindex="-1"
                :id="`file-${id}`"
                type="file"
                ref="files"
                :accept="accept[mode]"
                class="custom-file-input is-hidden"
                @change="upload"
            />
        </form>
    </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";

export default {
    data() {
        return {
            id: uuidv4(),
            accept: {
                video: "video/mp4",
                image: "image/x-png,image/gif,image/jpeg"
            },
            loading: false,
            response: false,
            hasImage: false,
            playerOptions: {
                // videojs options
                language: "en",
                height: 140,
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                    {
                        type: "video/mp4",
                        src: `/storage/${this.value}`
                    }
                ]
            }
        };
    },
    props: {
        value: {
            default: null
        },
        mode: {
            type: String,
            default: "image"
        },
        url: {
            type: String
        },
        label: {
            type: String
        },
        payload: {},
        src: {
            type: String
        },
        objectFit: {
            type: String,
            default: "cover"
        },
        ratio: {
            type: String,
            default: "16-9"
        },
        round: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "large"
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    methods: {
        deleteImage() {
            this.hasImage = null;
            this.$emit("input", {});
            this.$refs.form.reset();
        },
        upload() {
            var oFReader = new FileReader();

            oFReader.readAsDataURL(this.$refs.files.files[0]);
            oFReader.onload = oFREvent => {
                this.hasImage = oFREvent.target.result;
            };

            this.$set(this.inputVal, "file", this.$refs.files.files[0]);
            this.$set(this.inputVal, "name", this.$refs.files.files[0].name);
            // this.loading = true;

            // this.inputVal = this.$refs.files.files[0];
            // let formData = new FormData();
            // formData.append(this.mode, file);

            // const route = this.route(this.url, this.payload);

            // this.axios.post(route, formData).then(response => {
            //     this.loading = false;
            //     const filePath = `/storage/${response.data.url}`;

            //     if (this.mode == "video") {
            //         this.playerOptions.sources[0].src = filePath;
            //     }

            //     this.response = response.data.url;
            //     this.$emit("input", response.data.url);
            // });
        }
    }
};
</script>
<style lang="scss" scoped>
.title {
    margin-top: 0.5rem;

    color: var(--secondary);
}

.upload-container {
    &.inline {
        display: flex;
        align-items: center;

        .subtitle {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__image {
        max-width: 250px;

        &.size-small {
            width: 64px;

            i {
                font-size: 1.25rem;
            }
        }

        &.size-medium {
            width: 85px;

            i {
                font-size: 1.25rem;
            }
        }

        &.size-half {
            width: 50%;

            i {
                font-size: 1.25rem;
            }
        }

        &.size-full-width {
            width: 100%;
            max-width: none;

            i {
                font-size: 1.25rem;
            }
        }

        &.disabled {
            pointer-events: none;
        }

        &__ratio {
            position: relative;

            width: 100%;

            &.ratio-1-1 {
                padding-bottom: 100%;
            }

            &.ratio-16-9 {
                padding-bottom: 56%;
            }
            &.ratio-10-3 {
                padding-bottom: 30%;
            }

            &.round {
                & > * {
                    border-radius: 50%;
                }
            }

            & > *,
            img {
                position: absolute;

                width: 100%;
                height: 100%;

                border-radius: 6px;

                &.is-filled {
                    background-size: 32px;
                }
                &.cover {
                    object-fit: cover;
                }
                &.contain {
                    object-fit: contain;
                }
            }

            .delete {
                position: absolute;
                top: -10px;
                right: -10px;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 32px;
                height: 32px;

                cursor: pointer;
                transition: 0.2s ease all;

                border-radius: 50%;
                background-color: var(--secondary);

                &:hover {
                    background-color: var(--secondary-80);
                }

                i {
                    font-size: 0.9rem;
                }
            }

            &__content {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                border: none;
                background-color: var(--secondary-50);

                &:hover {
                    cursor: pointer;

                    background-color: var(--secondary-60);
                }
                // background-color: green;
                &.has-label {
                    i {
                        margin-top: 2rem;
                    }
                }
                i {
                    color: var(--secondary);

                    font-size: 1rem;
                }
            }
        }
    }
}

.title {
    display: inline-block;

    margin-bottom: 0.5rem;
}

.subtitle {
    display: inline-block;

    margin-top: 1rem;
}
</style>
<style lang="scss">
.video-js {
    border-radius: 6px;

    video {
        border-radius: 6px;
    }

    .vjs-big-play-button {
        top: 50%;
        left: 50%;

        margin-top: -0.75em;
        margin-left: -1.5em;
    }

    .vjs-control-bar {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}
</style>
