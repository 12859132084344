<template>
    <h1 :class="`${headingSize} one-line font-weight-bold text-${theme}`">
        <i v-if="icon" :class="[`i-${icon}`]" />
        <i v-else-if="breadcrumb" class="icon-breadcrumb i-arrow-right ml-1" />
        <slot />
    </h1>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        icon: {
            type: String,
            default: null
        },
        theme: {
            type: String,
            default: "secondary"
        },
        breadcrumb: {
            type: Boolean,
            default: false
        },
        headingSize: {
            type: String,
            default: "heading-3"
        }
    },
    methods: {}
};
</script>
<style lang="scss" scoped>
h1 {
    display: inline-block;
    overflow: visible;

    max-width: 250px;
    margin-bottom: 0;
    i {
        margin-right: 0.75rem;

        color: var(--grey);

        font-size: 1.5rem !important;

        &.icon-breadcrumb {
            font-size: 1rem !important;
        }
    }
}
</style>
