var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
        'btn',
        ("btn--" + _vm.theme),
        ("btn--" + _vm.variant),
        ("text-" + _vm.align),
        ("btn--" + _vm.size),
        _vm.iconOnly && "btn--icon-only"
    ],on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('div',{class:"spinner-border text-white",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_c('div',[(_vm.icon)?_c('i',{class:['icon', ("i-" + _vm.icon), ("icon--" + _vm.iconTheme)]}):_vm._e(),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }