<template>
    <div>
        <div class="radio-title mb-1" v-if="field.label">
            <strong>{{ field.label }}</strong>
        </div>
        <div class="field__inner">
            <b-form-datepicker
                :id="id"
                v-model="inputVal"
                locale="nl"
                :start-weekday="1"
            ></b-form-datepicker>
        </div>
    </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BFormDatepicker } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Input",
    components: {
        ValidationProvider,
        BFormDatepicker
    },
    data() {
        return {
            id: uuidv4()
        };
    },
    props: {
        field: {
            type: Object,
            default: () => {}
        },
        value: {
            type: [String, Number],
            default: ""
        },
        validation: {
            type: Boolean,
            default: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: ""
        },

        /** IF there is no pre-defined JSON Field we're forced to use props. */

        label: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },

        regex: {
            type: RegExp,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    methods: {}
};
</script>

<style lang="scss">
.b-form-datepicker {
    button,
    button:hover,
    button:focus {
        color: var(--grey);
    }
    label {
        padding: 0.75rem 1rem;

        color: var(--secondary-dark);

        font-size: 0.95rem !important;
    }
}
</style>
