var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formObserver",staticClass:"form-wrapper",attrs:{"disabled":!_vm.observer,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_vm._l((_vm.template.fields),function(field,key,index){return _c(_vm.setField(field),{key:index,tag:"component",class:[
            'fieldset',
            field.options &&
                field.options.size &&
                ("has-size-" + (field.options.size))
        ],attrs:{"field":field},on:{"updateData":function($event){return _vm.fillAddress($event)}},model:{value:(_vm.inputVal[key]),callback:function ($$v) {_vm.$set(_vm.inputVal, key, $$v)},expression:"inputVal[key]"}})}),_vm._v(" "),(_vm.button)?_c('div',{staticClass:"submit-wrapper"},[_c('Submit',{attrs:{"disabled":invalid || _vm.isLoading,"text":_vm.button,"theme":"blue"},nativeOn:{"click":function($event){return _vm.submit()}}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }