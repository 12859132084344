window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.axios = require("axios");

window.axios.defaults.withCredentials = true;

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");

    require("bootstrap");
} catch (e) {}

import { InertiaApp } from "@inertiajs/inertia-vue";

Vue.mixin({ methods: { route: window.route } });

import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "./Plugins/All";
import "./Mixins/All";

Vue.component("v-select", vSelect);

Vue.use(InertiaApp);
let app = document.getElementById("app");

new Vue({
    render: h =>
        h(InertiaApp, {
            props: {
                initialPage: JSON.parse(app.dataset.page),
                resolveComponent: name => {
                    return import(`@/Pages/${name}`).then(
                        module => module.default
                    );
                }
            }
        })
}).$mount(app);
