<template>
    <button
        type="submit"
        class="btn btn--submit"
        :disabled="disabled"
        :data-theme="theme"
    >
        <!-- Todo: toevoegen loader -->
        <!-- <div v-if="isLoading" class="spinner-border" /> -->
        <span>{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: "Submit",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        theme: {
            type: String,
            default: "orange"
        },
        text: {
            type: String,
            default: "Submit"
        }
    }
};
</script>

<style lang="scss" scoped>
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 3rem;

    cursor: pointer;

    border-radius: 4px;

    font-size: 0.95rem;
    font-weight: bold;
    line-height: 1rem;

    @extend %btn-variants;

    &:disabled {
        pointer-events: none;

        opacity: 0.5;
    }
}

%btn-variants {
    &.btn--filled {
        @include transition(0.3s background-color);
        &[data-theme="primary"] {
            @extend %btn-theme-orange;
        }
    }
}

%btn-theme-orange {
    color: $white;
    background-color: $primary;

    &:hover {
        // background-color: var(--primary-dark);
    }
}
</style>
