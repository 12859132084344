import { render, staticRenderFns } from "./CoreForm.vue?vue&type=template&id=2b27e751&scoped=true&"
import script from "./CoreForm.vue?vue&type=script&lang=js&"
export * from "./CoreForm.vue?vue&type=script&lang=js&"
import style0 from "./CoreForm.vue?vue&type=style&index=0&id=2b27e751&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b27e751",
  null
  
)

export default component.exports