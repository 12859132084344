var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validator",staticClass:"field field--input",attrs:{"tag":"div","disabled":!_vm.validation,"rules":_vm.setRules()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field__inner"},[((_vm.field ? _vm.field.type : _vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],class:[
                { 'is-focused': _vm.value },
                { 'has-error': errors.length }
            ],attrs:{"id":("field-" + _vm._uid + "-input"),"disabled":_vm.field ? _vm.field.disabled : _vm.disabled,"required":_vm.rule('required'),"autocomplete":_vm.autocomplete,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputVal)?_vm._i(_vm.inputVal,null)>-1:(_vm.inputVal)},on:{"focus":function($event){return _vm.$emit('focus')},"change":function($event){var $$a=_vm.inputVal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputVal=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputVal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputVal=$$c}}}}):((_vm.field ? _vm.field.type : _vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],class:[
                { 'is-focused': _vm.value },
                { 'has-error': errors.length }
            ],attrs:{"id":("field-" + _vm._uid + "-input"),"disabled":_vm.field ? _vm.field.disabled : _vm.disabled,"required":_vm.rule('required'),"autocomplete":_vm.autocomplete,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputVal,null)},on:{"focus":function($event){return _vm.$emit('focus')},"change":function($event){_vm.inputVal=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],class:[
                { 'is-focused': _vm.value },
                { 'has-error': errors.length }
            ],attrs:{"id":("field-" + _vm._uid + "-input"),"disabled":_vm.field ? _vm.field.disabled : _vm.disabled,"required":_vm.rule('required'),"autocomplete":_vm.autocomplete,"type":_vm.field ? _vm.field.type : _vm.type},domProps:{"value":(_vm.inputVal)},on:{"focus":function($event){return _vm.$emit('focus')},"input":function($event){if($event.target.composing){ return; }_vm.inputVal=$event.target.value}}}),_vm._v(" "),_c('label',{attrs:{"for":("field-" + _vm._uid + "-input")}},[_c('span',[_vm._v(_vm._s(_vm.field ? _vm.field.label : _vm.label))]),_vm._v(" "),(_vm.rule('required'))?_c('span',[_vm._v("*")]):_vm._e()]),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"spinner"}):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }