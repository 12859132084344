<template>
    <div class="field field--address">
        <ValidationObserver
            ref="formObserver"
            :disabled="!observer"
            class="field__observer"
        >
            <div class="field__inner">
                <InputText
                    v-model="inputVal['zip_code']"
                    label="Postcode"
                    class="field field--zipcode"
                    :validation="false"
                ></InputText>
                <InputText
                    v-model="inputVal['house_number']"
                    label="Huisnr."
                    type="number"
                    class="field field--house_number"
                    :validation="false"
                ></InputText>
                <InputText
                    v-model="inputVal['addition']"
                    label="Toev."
                    class="field field--addition"
                    :validation="false"
                    :required="false"
                ></InputText>
            </div>
            <!-- <span
                v-if="
                    errors && Object.values(errors).filter(x => x.length).length
                "
                class="msg msg--error"
                >Vul de velden juisten in.
            </span> -->
        </ValidationObserver>
        <div class="field__observer mt-address">
            <div class="field__inner">
                <InputText
                    v-model="inputVal['street']"
                    label="Straatnaam"
                    class="subfield"
                ></InputText>
                <InputText
                    v-model="inputVal['city']"
                    label="Woonplaats"
                    class="subfield"
                ></InputText>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import InputText from "@/Components/form/fields/Input";

export default {
    name: "Address",
    components: {
        ValidationObserver,
        InputText
    },
    props: {
        field: {
            type: Object,
            default: () => {}
        },
        value: {
            type: Object,
            default: () => ({})
        },
        observer: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    watch: {
        inputVal: {
            handler(val) {
                this.getAddress();
                this.$emit("input", val);
            },
            deep: true
        }
    },
    mounted() {
        if (!this.inputVal["street"]) {
            this.getAddress();
        }
    },
    methods: {
        searchAddress: _.debounce(function() {
            window.axios
                .post(this.route("address.lookup.autocomplete"), {
                    zip_code: this.inputVal.zip_code,
                    house_number: this.inputVal.house_number
                })
                .then(response => {
                    this.$set(
                        this.inputVal,
                        "city",
                        response.data.municipality
                    );
                    this.$set(this.inputVal, "street", response.data.street);

                    this.$set(this.inputVal, "country", response.data.country);
                    this.$set(this.inputVal, "region", response.data.province);
                    this.$set(this.inputVal, "latitude", response.data.lat);
                    this.$set(this.inputVal, "longitude", response.data.lng);
                })
                .catch(err => {
                    this.$set(this.inputVal, "city", null);
                    this.$set(this.inputVal, "street", null);
                    this.$set(this.inputVal, "country", null);
                    this.$set(this.inputVal, "region", null);
                    this.$set(this.inputVal, "latitude", null);
                    this.$set(this.inputVal, "longitude", null);
                    console.log(err);
                });
        }, 500),
        getAddress() {
            setTimeout(() => {
                if (this.$refs.formObserver.flags.valid) this.searchAddress();
            }, 50);
        }
        // gfag() {
        //     const validZipCode = this.form.address.zip_code.length === 6;

        //     if (validZipCode) {
        //         let form = new FormData();
        //         form.append("zip_code", this.form.address.zip_code);
        //         form.append("house_number", this.form.address.house_number);

        //         let self = this;

        //         window.axios
        //             .post(this.route("address.lookup.autocomplete"), form)
        //             .then(
        //                 function(response) {
        //                     self.form.address.country = response.data.country;
        //                     self.form.address.region = response.data.province;
        //                     self.form.address.city = response.data.municipality;
        //                     self.form.address.zip_code =
        //                         response.data.postalCode;
        //                     self.form.address.house_number =
        //                         response.data.streetNumber;
        //                     self.form.address.street = response.data.street;
        //                     self.form.address.latitude = response.data.lat;
        //                     self.form.address.longitude = response.data.lng;
        //                 }.bind(self)
        //             )
        //             .catch(function(error) {
        //                 //
        //             });
        //     }
        // }
    }
};
</script>

<style lang="scss" scoped>
.fieldset {
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    .field {
        margin: 0.5rem;

        &__inner {
            position: relative;

            display: flex;

            width: 100%;
            margin: 0;
        }
        &__observer {
            width: calc(100% + 1rem);
            margin: -0.5rem;
        }
    }

    .field--zipcode {
        flex-basis: calc(40% - 1rem);
    }

    .field--house_number {
        flex-basis: calc(30% - 1rem);
    }

    .field--addition {
        flex-basis: calc(30% - 1rem);
    }

    .field--house_number {
        flex-basis: calc(30% - 1rem);
    }

    .field--addition {
        flex-basis: calc(30% - 1rem);
    }

    .subfield {
        flex-basis: calc(50% - 1rem);
    }
    .mt-address {
        margin-top: 0.5rem;
    }
}
</style>
