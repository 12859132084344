<template>
    <ValidationProvider
        ref="validator"
        v-slot="{ errors }"
        class="field field--select"
        tag="div"
    >
        <vSelect
            :id="`field-${_uid}-select`"
            v-model="selectValue"
            :label="field.selectLabel"
            :options="field.options"
            class="v-select"
            :class="{ 'is-focused': focus || inputVal }"
            :clearable="false"
            :placeholder="field.label"
            @search:focus="isFocused()"
            @search:blur="focus = false"
        ></vSelect>
        <input type="hidden" v-model="inputVal" ref="hiddenField" />
        <span v-if="errors.length && validation" class="msg msg--error">{{
            errors[0]
        }}</span>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import vSelect from "vue-select";
export default {
    name: "Select",
    components: {
        ValidationProvider,
        vSelect
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        field: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ""
        },
        selectLabel: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            focus: false,
            selectValue: null
        };
    },
    computed: {
        inputVal: {
            get() {
                if (this.value) {
                    this.selectValue = [...this.field.options].filter(
                        option => {
                            return option.id == this.value;
                        }
                    )[0];
                }
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    watch: {
        selectValue: {
            handler(val) {
                this.inputVal = val.id;
            }
        }
    },
    methods: {
        isFocused() {
            this.focus = true;
            this.$emit("focus");
        }
    }
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
<style lang="scss">
.v-select {
    border: 1px solid var(--grey-25);
    border-radius: 4px;

    .vs__selected-options {
        padding: 0;
    }

    .vs__dropdown-toggle {
        padding: 0;

        border: none;
    }
    .vs__selected {
        margin: 0;
        padding: 0.75rem 1.5rem 0.75rem 1rem !important;

        color: var(--secondary-dark) !important;
    }
    input {
        padding: 0.5rem 1.5rem 0.75rem 1rem !important;

        color: var(--secondary-dark) !important;

        &::placeholder {
            color: var(--dark-50);
        }
    }
    label {
        display: none;
    }
}
</style>
