import { extend } from "vee-validate";
import {
    required,
    email,
    digits,
    regex,
    numeric
} from "vee-validate/dist/rules";
// import PhoneNumber from "awesome-phonenumber";

// Load Validation Rules
extend("email", email);
extend("required", {
    ...required,
    message: "This field is required"
});
extend("digits", digits);
extend("regex", regex);
extend("numeric", numeric);
