<template>
    <button
        :class="[
            'btn',
            `btn--${theme}`,
            `btn--${variant}`,
            `text-${align}`,
            `btn--${size}`,
            iconOnly && `btn--icon-only`
        ]"
        @click="$emit('click')"
    >
        <div v-if="loading" class="text-center">
            <div :class="`spinner-border text-white`" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else>
            <i
                v-if="icon"
                :class="['icon', `i-${icon}`, `icon--${iconTheme}`]"
            />
            <slot />
        </div>
    </button>
</template>
<script>
export default {
    data() {
        return {};
    },

    props: {
        theme: {
            default: "primary"
        },
        variant: {
            default: "filled"
        },
        align: {
            default: "center"
        },
        icon: {
            default: null
        },
        size: {
            default: "normal"
        },
        iconOnly: {
            default: false
        },
        iconTheme: {
            default: "icon-default"
        },
        loading: {
            default: false
        }
    },
    methods: {}
};
</script>
<style lang="scss" scoped>
.btn {
    padding: 0.75rem;

    color: var(--white);
    border: 1px solid;
    border-radius: 6px;

    font-weight: 500;
    &--small {
        padding: 0.4rem 0.75rem 0.5rem 0.75rem;

        font-size: 0.9rem;
        font-weight: 600;
    }
    &--fullwidth {
        width: 100%;
    }
    &--outline {
        background-color: transparent !important;
    }
    &--primary {
        border-color: var(--primary);
        background-color: var(--primary);
    }
    &--secondary {
        border-color: var(--secondary);
        background-color: var(--secondary);
        &:hover,
        &.active {
            border-color: var(--secondary-80);
            background-color: var(--secondary-80);
        }
    }
    &--success {
        border-color: var(--complete);
        background-color: var(--complete);

        &:disabled {
            pointer-events: none;
        }
        &:hover,
        &.active {
            background-color: var(--complete-hover);
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 185, 0, 0.25);
        }
    }
    &--light {
        color: var(--dark-50);
        border-color: var(--grey-25);
        background-color: var(--grey-10);
    }

    &--icon-only {
        i {
            margin-right: 0 !important;
        }
    }

    .icon {
        margin-right: 0.5rem;

        &--primary {
            color: var(--primary);
        }
    }
    .spinner-border {
        width: 1.25rem !important;
        height: 1.25rem !important;
    }
}
</style>
