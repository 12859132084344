<template>
    <ValidationObserver
        ref="formObserver"
        v-slot="{ invalid }"
        :disabled="!observer"
        tag="div"
        class="form-wrapper"
    >
        <component
            :is="setField(field)"
            v-for="(field, key, index) in template.fields"
            :key="index"
            v-model="inputVal[key]"
            :class="[
                'fieldset',
                field.options &&
                    field.options.size &&
                    `has-size-${field.options.size}`
            ]"
            :field="field"
            @updateData="fillAddress($event)"
        />

        <div v-if="button" class="submit-wrapper">
            <Submit
                :disabled="invalid || isLoading"
                :text="button"
                theme="blue"
                @click.native="submit()"
            >
            </Submit>
        </div>

        <!-- {{ invalid }} -->
    </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import InputText from "@/Components/form/fields/Input";
import InputRadio from "@/Components/form/fields/Radio";
import InputAddress from "@/Components/form/fields/Address";
import InputCheckbox from "@/Components/form/fields/Checkbox";
import InputSelect from "@/Components/form/fields/Select";
import InputTags from "@/Components/form/fields/Tags";
import InputDate from "@/Components/form/fields/Date";
import Submit from "@/Components/form/fields/Submit";

export default {
    name: "BaseForm",
    components: {
        ValidationObserver,
        Submit
    },
    props: {
        template: {
            type: Object,
            default: () => {}
        },
        value: {
            type: Object,
            default: () => ({})
        },
        observer: {
            type: Boolean,
            default: false
        },
        button: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            initialized: false,
            isLoading: false,
            edited: false
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    watch: {
        inputVal: {
            handler() {
                if (this.initialized) {
                    this.$emit("edited", true);
                    this.edited = true;
                }
                this.initialized = true;
            },
            deep: true
        }
    },
    methods: {
        hasObserver() {
            if (this.observer) {
                return ValidationObserver;
            } else {
                return "div";
            }
        },
        setField(field) {
            if (
                field.type === "text" ||
                field.type === "number" ||
                field.type === "email" ||
                field.type === "password"
            ) {
                return InputText;
            } else if (field.type === "radio") {
                return InputRadio;
            } else if (field.type === "address") {
                return InputAddress;
            } else if (field.type === "checkbox") {
                return InputCheckbox;
            } else if (field.type === "select") {
                return InputSelect;
            } else if (field.type === "tags") {
                return InputTags;
            } else if (field.type === "date") {
                return InputDate;
            }
            return false;
        },
        fillAddress(data) {
            this.inputVal = { ...this.inputVal, ...data };
        },
        async submit() {
            const isValid = await this.$refs.formObserver.validate();

            if (!isValid) {
                console.log("error");
            }

            console.log("🐿 ship it");
            this.$emit("submitForm", this.formData);
        }
    }
};
</script>

<style scoped lang="scss">
$spacing: 0.5rem;

.form-wrapper {
    display: flex;
    flex-wrap: wrap;

    .fieldset,
    .submit-wrapper {
        flex-basis: 100%;

        margin: 0.5rem 0;
    }

    .fieldset {
        @for $i from 1 through 10 {
            &.has-size-#{$i} {
                flex-basis: calc(((#{$i} / 10) * 100%) - (#{$spacing} * 2));
            }
        }
    }

    >>> .msg {
        flex-basis: 100%;

        margin-top: 0.5rem;

        font-size: 0.8125rem;

        &--error {
            color: #ff1600;
        }
    }
}
</style>
