<template>
    <ValidationProvider
        ref="validator"
        v-slot="{ errors }"
        class="radio-wrapper"
        tag="div"
        :rules="{
            required: !field.optional
        }"
    >
        <div class="mb-1" v-if="field.name">
            <strong>{{ field.name }}</strong>
        </div>
        <div class="field__inner">
            <div
                v-for="(item, index) in field.items"
                :key="index"
                class="field field--radio"
            >
                <input
                    :id="`field-${_uid}-radio-${index}`"
                    v-model="inputVal"
                    :type="field.type"
                    :disabled="field.disabled || disabled"
                    :value="item.value"
                    :required="field.optional ? true : false"
                />
                <label :for="`field-${_uid}-radio-${index}`">{{
                    item.label
                }}</label>
            </div>
        </div>

        <span>{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
    name: "Radio",
    components: {
        ValidationProvider
    },
    props: {
        field: {
            type: Object,
            default: () => {}
        },
        value: {
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            settings: {
                checked: true
            }
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    created() {
        const current = this.field.items.filter(x => x.checked);
        if (!this.inputVal && current.length) {
            this.inputVal = current[0].value;
        }
    }
};
</script>

<style lang="scss" scoped>
.radio-wrapper {
    .field__inner {
        display: flex;

        margin: -0.5rem;
    }

    .field--radio {
        display: inline-flex;

        margin: 0.5rem;

        input {
            position: absolute;

            display: inherit;

            margin: 0;
            padding: 0;

            text-indent: -999999%;

            opacity: 0;

            & + label {
                position: relative;

                display: inline-flex;
                align-items: center;

                margin-bottom: 0.5rem;

                cursor: pointer;

                font-size: 0.9375rem;
                line-height: 1rem;

                &::before {
                    width: 16px;
                    height: 16px;
                    margin-right: 0.5rem;

                    content: "";

                    border: 1px solid #ebedef;
                    border-radius: 50%;
                }

                &::after {
                    position: absolute;
                    left: 3px;

                    visibility: hidden;

                    width: 10px;
                    height: 10px;
                    margin-right: 0.5rem;

                    content: "";

                    opacity: 0;
                    border-radius: 50%;
                    background-color: var(--primary);
                }
            }

            &:disabled + label {
                pointer-events: none;

                opacity: 0.5;
            }
        }

        input:checked + label {
            &::after {
                visibility: visible;

                opacity: 1;
            }
        }
    }
}
</style>
